import styled from '@emotion/styled';
import { DOMAIN } from 'config';
import { Trans, t } from '@lingui/macro';
import { ArrowUUpLeft } from '@phosphor-icons/react';

export const OldSiteLink = () => {
  return (
    <LinkContainer>
      <a
        href={DOMAIN}
        title={t`You are currently on Delogue 2.0 which is in beta stage, click here to go back to classic version.`}
      >
        <ArrowUUpLeft color="white" size={20} />
        <Trans>Return to classic version</Trans>
      </a>
    </LinkContainer>
  );
};

const LinkContainer = styled.div`
  a {
    color: ${p => p.theme.palette.text.primaryOnBrand};
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${p => p.theme.palette.fg.infoPrimary};
  :hover {
    background-color: ${p => p.theme.palette.bg.infoSecondary};
  }
`;
