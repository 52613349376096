const DEFAULT_LANG = 'en';
export function getDisplayNames(lang = DEFAULT_LANG): Intl.DisplayNames {
  try {
    return new Intl.DisplayNames(lang, {
      type: 'region',
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return new Intl.DisplayNames(DEFAULT_LANG, {
      type: 'region',
    });
  }
}
