import { faker } from '@faker-js/faker/locale/en';
import { type Task } from 'features/taskboard/types/Tasks';
import { makeData } from 'mocks/helpers';
import { taskNotes } from './taskNote';
import { taskUsers } from './taskUser';
import { loggedInUser } from 'mocks/data/user';
import { checkModule, checkRole } from 'features/accessControl/utils';
export const newTask = (identifyer: number): Task => {
  // faker.seed with a consistent number will always generate the same data
  //faker.seed(identifyer);
  const isComplianceModuleActive =
    checkModule(workflowTaskEntityType[3], loggedInUser?.Organization.Modules) &&
    (checkRole('Compliance Admin', loggedInUser?.RoleNames) ||
      checkRole('Compliance User', loggedInUser?.RoleNames));
  const taskId = faker.number.int();
  const designerOrganizationId = faker.number.int();
  const entityTypes = [
    workflowTaskEntityType[0],
    workflowTaskEntityType[1],
    workflowTaskEntityType[2],
  ];
  if (isComplianceModuleActive) {
    entityTypes.push(workflowTaskEntityType[3]);
  }
  const taskEntityType = faker.helpers.arrayElement(entityTypes);
  /* const sampleRequestState = faker.helpers.arrayElement([
    sampleRequestStates.Requested,
    sampleRequestStates.Sent,
    sampleRequestStates.Received,
    sampleRequestStates.Commented,
    sampleRequestStates.Cancelled,
    sampleRequestStates.Confirmed,
    sampleRequestStates.Planned,
  ]);
  const state =
    taskEntityType === taskEntityTypes.Sampling
      ? sampleRequestState
      : faker.helpers.arrayElement([
          taskStates.ToDo,
          taskStates.InProgress,
          taskStates.Stuck,
          taskStates.Done,
        ]); */
  const state = faker.helpers.arrayElement([
    taskStates[0],
    taskStates[1],
    taskStates[2],
    taskStates[3],
  ]);
  const assignedToPerson = faker.helpers.arrayElement(taskUsers);
  // return "random" data
  return {
    id: taskId,
    taskName: faker.company.catchPhrase(),
    entityType: faker.number.int({ min: 0, max: 3 }),
    styleId: faker.number.int(),
    itemId: faker.number.int(),
    viewedOn: faker.date.recent(),

    certificateId: faker.number.int(),
    adminWorkflow: {
      id: faker.number.int(),
      name: faker.company.name(),
      organizationId: designerOrganizationId,
      position: faker.number.int(),
      colorCode: faker.internet.color(),
    },
    assignedToPerson,
    taskState: state,
    deadline: taskEntityType === workflowTaskEntityType[2] ? null : faker.date.future(),
    assignedTo:
      assignedToPerson.organizationTypeId === 1 ? 'CompanyPerson' : 'SupplierContactPerson',
    toDoBy: faker.helpers.arrayElement(taskUsers),
    inProgressBy: faker.helpers.arrayElement(taskUsers),
    doneBy: faker.helpers.arrayElement(taskUsers),
    assignedBy: faker.helpers.arrayElement(taskUsers),
    doneDate: faker.date.recent().toDateString(),
    lastUpdatedDate: faker.date.recent().toDateString(),
    lastUpdatedBy: faker.helpers.arrayElement(taskUsers),
    priority: faker.helpers.arrayElement([taskPriority[1], taskPriority[2], taskPriority[3]]),
    organizationId: faker.number.int(),
    imageUrl:
      taskEntityType === workflowTaskEntityType[1] || taskEntityType === workflowTaskEntityType[2]
        ? faker.image.urlPicsumPhotos()
        : '',
    taskboardBrand: {
      id: faker.number.int(),
      name: faker.lorem.word(5),
    },
    taskboardCategory: faker.lorem.word(5),
    taskboardSupplier: {
      id: faker.number.int(),
      name: faker.lorem.word(5),
    },
    taskboardSeason: {
      id: faker.number.int(),
      name: faker.lorem.word(5),
    },
    linkedTo: {
      fieldData:
        taskEntityType === workflowTaskEntityType[0]
          ? { id: 0, name: '' }
          : {
              id: faker.number.int(),
              name: faker.commerce.productName(),
            },
      userDefinedId: faker.number.int().toString(),
    },
    notes: [...taskNotes(taskId)],
    deletedBy: null,
    deletedOn: null,
    styleGroup:
      taskEntityType === workflowTaskEntityType[1]
        ? {
            id: faker.number.int(),
            name: faker.lorem.word(5),
          }
        : null,
    certificateState:
      taskEntityType === workflowTaskEntityType[3]
        ? faker.helpers.arrayElement([
            certificateStates['-1'],
            certificateStates[0],
            certificateStates[1],
            certificateStates[2],
            certificateStates[3],
            certificateStates[4],
            certificateStates[5],
          ])
        : '',
    certificateInstitute:
      taskEntityType === workflowTaskEntityType[3]
        ? {
            id: faker.number.int(),
            name: faker.lorem.word(5),
          }
        : null,
    certificateValidFrom: taskEntityType === workflowTaskEntityType[3] ? faker.date.recent() : null,
    certificateValidTo: taskEntityType === workflowTaskEntityType[3] ? faker.date.future() : null,
    certificateType:
      taskEntityType === workflowTaskEntityType[3]
        ? faker.helpers.arrayElement([
            certificateTypes.Product,
            certificateTypes.Scope,
            certificateTypes.TestReport,
            certificateTypes.Transaction,
          ])
        : null,
    styleHeaderCustomField:
      taskEntityType === workflowTaskEntityType[1]
        ? {
            id: faker.number.int(),
            customFieldName: faker.lorem.word(5),
            customFieldValue: faker.lorem.word(5),
          }
        : null,
    createdDate: faker.date.recent(),
    isTaskUnread: faker.datatype.boolean(),
  };
};

export const taskStates = {
  //'-1': 'None',
  0: 'To Do',
  1: 'In Progress',
  2: 'Stuck',
  3: 'Done',
};

export const workflowTaskEntityType = {
  0: 'Free',
  1: 'Style',
  2: 'Item',
  3: 'Certificate',
  // Sampling: 'Sampling',
};
export const sampleRequestStates = {
  Requested: 'Requested',
  Sent: 'Sent',
  Received: 'Received',
  Commented: 'Commented',
  Cancelled: 'Cancelled',
  Confirmed: 'Confirmed',
  Planned: 'Planned',
};

export const certificateStates = {
  '-1': 'None',
  0: 'Draft',
  1: 'InProgress',
  2: 'Valid',
  3: 'Expired',
  4: 'Cancelled',
  5: 'Passed',
  6: 'Failed',
};

export const certificateTypes = {
  Product: 'Product',
  Scope: 'Scope',
  TestReport: 'Test Report',
  Transaction: 'Transaction',
};

export const taskPriority = {
  1: 'Low',
  2: 'Medium',
  3: 'High',
};

export const assignedTo = {
  '-1': 'All',
  0: 'None',
  1: 'CompanyPerson',
  2: 'Brand Contact',
  3: 'Supplier Contact',
  4: 'SupplierCompanyPerson',
};

export const tasks = makeData(101, newTask);
