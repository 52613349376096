import { t } from '@lingui/macro';

export const codeList = {
  invalid_input_to_get_task: t`Invalid input to get task.`,
  invalid_input_to_get_filters: t`Your inputs are not correct to get filter options.`,
  rbac_fail_to_get_filters: t`Your user permissions do not allow you to get filter options.`,
  error_get_filters_contact_support: t`An error occurred while getting filter options, please try again. If the issue persists, please contact support.`,
  unauthorised_user_to_get_filters: t`User is not authorised.`,
  unauthorised_user_to_get_entity: t`User is not authorised to get entity.`,
  error_get_entity_contact_support: t`Entity not found. Please contact support.`,
  invalid_input_to_get_users: t`Your inputs are not correct to get users.`,
  unauthorised_users_to_get_user: t`You are not authorized to get users.`,
  rbac_fail_to_get_users: t`Your user permissions do not allow you to get the users.`,
  error_get_users_contact_support: t`An error occurred while getting users, please try again. If the issue 
persists, please contact support.`,
  invalid_input_to_get_suppliers: t`Your inputs are not correct to get suppliers.`,
  unauthorised_users_to_get_suppliers: t`User is not authorised.`,
  rbac_fail_to_get_suppliers: t`Your user permissions do not allow you to get suppliers.`,
  error_get_suppliers_contact_support: t`An error occurred while getting suppliers, please try again. If the issue persists, please contact support.`,
  invalid_input_task: t`Your inputs are not correct in creating a task.`,
  create_task_unauthorised_user: t`You are not authorized to create a task.`,
  unauthorised_user_to_get_task: t`You are not authorised to view tasks.`,
  rbac_fail_to_get_task: t`Your user permissions do not allow you to get tasks.`,
  rbac_fail_to_delete_task: t`Your user permissions do not allow you to delete tasks.`,
  error_delete_task_contact_support: t`Task deletion failed, please try again. If the issue persists, please contact support.`,
  task_created: t`Task has been created.`,
  invalid_input_to_create_task: t`The task could not be created since it contains invalid data.`,
  unauthorised_user_to_create_task: t`You are not authorised to create tasks.`,
  create_task_failure_contact_support: t`Task creation failed, please try again. If the issue persists, please contact support.`,
  error_get_task_contact_support: t`Tasks could not be retrieved, please try again. If the issue persists, please contact support.`,
  task_updated: t`The change was successful.`,
  task_updated_refresh_page: t`No changes were made. Other users may be working on these tasks. Refresh the page to see their changes.`,
  invalid_input_to_update_task: t`This action is not allowed.`,
  unauthorised_user_to_update_task: t`You are not authorised to update tasks.`,
  error_update_task_contact_support: t`Task changes failed, please try again. If the issue persists, please contact support.`,
  task_deleted: t`Deletion was successful.`,
  task_deleted_refresh_page: t`The selection had already been deleted. Other users may be working on these tasks. Refresh the page to see their changes.`,
  invalid_input_to_delete_task: t`This action is not allowed.`,
  unauthorised_user_to_delete_task: t`You are not authorised to delete tasks.`,
  note_created: t`A note for the task has been created successfully.`,
  invalid_input_to_create_note: t`This action is not allowed.`,
  unauthorised_user_to_create_note: t`You are not authorised to create notes.`,
  create_note_failure_contact_support: t`Note creation failed, please try again. If the issue persists, please contact support.`,
  note_deleted: t`Note has been deleted.`,
  note_deleted_refresh_page: t`The note had already been deleted. Other users may be working on these notes. Refresh the page to see their changes.`,
  invalid_input_to_delete_note: t`This action is not allowed.`,
  unauthorised_user_to_delete_note: t`You are not authorised to delete notes.`,
  error_delete_note_contact_support: t`Note deletion failed, please try again. If the issue persists, please contact support.`,
  rbac_fail_create_task: t`Your user permissions do not allow you to create a task.`,
  rbac_fail_to_delete_note: t`Your user permissions do not allow you to delete a note.`,
  rbac_fail_create_note: t`Your user permissions do not allow you to create a note.`,
  rbac_fail_to_single_delete_task: t`Your user permissions do not allow you to delete a task.`,
  rbac_fail_to_bulk_delete_task: t`Bulk actions for tasks are not enabled for your subscription. Please reach out to our support if you wish to enable this feature.`,
  rbac_fail_to_single_update_task: t`Your user permissions do not allow you to update a task.`,
  rbac_fail_to_bulk_update_task: t`Bulk actions for tasks are not enabled for your subscription. Please reach out to our support if you wish to enable this feature.`,
};
