import { faker } from '@faker-js/faker/locale/en';
import { Note } from 'features/taskboard/types/Tasks';
import { makeChildData } from 'mocks/helpers';
import { taskUsers } from './taskUser';

export const newNote = (taskId: number): Note => ({
  id: faker.number.int(),
  taskId,
  note: `${faker.lorem.sentence()}`,
  createdBy: faker.helpers.arrayElement(taskUsers),
  createdOn: faker.date.recent(),
  deletedBy: null,
  deletedOn: null,
});

export const taskNotes = (taskId: number) => {
  return makeChildData(taskId, 5, newNote);
};
