import type { ChipProps as MuiChipProps } from '@mui/material';
import { Chip as MuiChip, Tooltip } from '@mui/material';
import { colors } from 'styles/theme/colors';
import { X } from '@phosphor-icons/react';

export type ChipProps = Pick<
  MuiChipProps,
  'label' | 'onDelete' | 'id' | 'component' | 'variant' | 'size' | 'sx'
> & {
  color?: string;
  deleteIconColor?: string;
  white?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
  isEditable?: boolean;
  renderLabel?: (label: MuiChipProps['label']) => React.ReactNode;
};

export const Chip = ({
  label,
  onDelete,
  component = 'li',
  variant = 'filled',
  color = '',
  white = false,
  onClick,
  isEditable = true,
  deleteIconColor,
  renderLabel,
  ...rest
}: ChipProps) => {
  if (!label) return null;

  let sx: MuiChipProps['sx'] = {};
  if (color) {
    sx = {
      ...(rest.sx ?? {}),
      backgroundColor: color,
      color: white ? colors.white : colors.black,
      borderRadius: '6px',
    };
  }
  if (variant === 'outlined') {
    sx = {
      ...(rest.sx ?? {}),
      borderColor: color,
      color,
      fontWeight: 600,
      fill: 'transparent',
      borderRadius: '6px',
    };
  }

  return (
    <Tooltip title={renderLabel ? renderLabel(label) : label}>
      <MuiChip
        {...rest}
        component={component}
        onDelete={isEditable ? onDelete : undefined}
        label={renderLabel ? renderLabel(label) : label}
        variant={variant}
        sx={sx}
        deleteIcon={<X data-testid="CancelIcon" size={16} color={deleteIconColor || color} />}
        onClick={onClick}
      />
    </Tooltip>
  );
};
