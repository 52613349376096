import { faker } from '@faker-js/faker/locale/en';
import { Compliance } from 'features/userExampleFeature/types';
import { makeData } from 'mocks/helpers';

export const newCompliance = (identifyer: number): Compliance => {
  // faker.seed with a consistent number will always generate the same data
  faker.seed(identifyer);

  // return "random" data
  return {
    Id: faker.string.uuid(),
    Name: faker.person.lastName(),
  };
};

export const compliances = makeData(10, newCompliance);
