import { complianceEndpoints } from './handlers/exampleCompliance';
import { authEndpoints } from './handlers/auth';
import { imageEndpoints } from './handlers/images';
import { accessControlEndpoints } from './handlers/accessControl';
import { taskEndpoints } from './handlers/task';
import { genericEndpoints } from './handlers/genericData';
import { t } from '@lingui/macro';
import { VITE_MOCK_ONLY } from 'config';
import type { Server } from 'miragejs';

const enabledEndpoints = (server: Server) => {
  complianceEndpoints(server);
  imageEndpoints(server);
  accessControlEndpoints(server);
};

const disabledEndpoints = (server: Server) => {
  authEndpoints(server);
  taskEndpoints(server);
  genericEndpoints(server);
};

export const endpoints = (server: Server) => {
  enabledEndpoints(server);
  if (VITE_MOCK_ONLY) {
    disabledEndpoints(server);
  }
};

export const HTTP_RESPONSE_CODES = {
  OK: 200,
  UPDATED: 200,
  CREATED: 201,
  NO_CHANGE: 204,
  BAD_INPUT: 400,
  UNAUTHORIZED: 401,
  ACTION_NOT_ALLOWED: 403,
  ENTITY_NOT_FOUND: 404,
  SERVER_ERROR: 500,
};

export const ERROR_MESSAGES = {
  [HTTP_RESPONSE_CODES.NO_CHANGE]: t`204 No change`,
  [HTTP_RESPONSE_CODES.BAD_INPUT]: t`400 Bad input`,
  [HTTP_RESPONSE_CODES.UNAUTHORIZED]: t`'401 User is not authorised`,
  [HTTP_RESPONSE_CODES.BAD_INPUT]: t`403 Action not allowed`,
  [HTTP_RESPONSE_CODES.ENTITY_NOT_FOUND]: t`404 Entity not found`,
  [HTTP_RESPONSE_CODES.SERVER_ERROR]: t`500 Server error`,
};
