import { paginate } from 'mocks/helpers';
import { compliances } from 'mocks/data/compliance';
import { Response } from 'miragejs';
import type { Server } from 'miragejs';

export const complianceEndpoints = (server: Server) => {
  server.get(`/Compliance/:id`, (schema, request) => {
    const { id } = request.params;

    const complianceById = compliances.find(compliance => compliance.Id.toString() === id);

    if (!complianceById) {
      return new Response(404, undefined, {
        status: '404 Not found',
        code: `Could not find a compliance certificate with the id of ${id}`,
      });
    }
    return complianceById;
  });
  server.get(`/Compliances`, (schema, request) => {
    const url = new URL(request.url);
    const perPage = url.searchParams.get('perPage') || 10;
    const page = url.searchParams.get('page') || 1;

    if (perPage || page) {
      const paginated = paginate({
        items: compliances,
        perPage: Number(perPage),
        page: Number(page),
      });

      return paginated;
    }

    return compliances;
  });
};
