import {
  styled,
  TextField,
  formHelperTextClasses,
  inputBaseClasses,
  inputLabelClasses,
  outlinedInputClasses,
  Box,
} from '@mui/material';
import type { SxProps, InputProps, TextFieldProps } from '@mui/material';
import { AccessControl } from 'features/accessControl';
import { CommonLabelSeparateProps } from './types';
import { Check, X } from '@phosphor-icons/react';
import { textVariant } from 'components/text';
import { colors } from 'styles/theme/colors';

export type TextInputProps = CommonLabelSeparateProps & {
  autoFocus?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  defaultValue?: string;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  id?: string;
  label?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  ref?: React.Ref<HTMLInputElement>;
  required?: boolean;
  sx?: SxProps;
  type?: 'email' | 'hidden' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url';
  value?: string;
  variant?: 'filled' | 'outlined' | 'standard' | 'unstyled';
  InputProps?: InputProps;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  helperText?: string;
  autoComplete?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  size?: TextFieldProps['size'];
};

const TextInputDefault = ({
  autoFocus,
  color,
  defaultValue,
  disabled,
  error,
  fullWidth,
  id,
  label,
  name,
  onChange,
  placeholder,
  ref,
  required,
  sx,
  type,
  value,
  variant = 'standard',
  helperText,
  onBlur,
  ...props
}: TextInputProps) => {
  return (
    <AccessControl write fallback={value} roleNames={['Test User', 'Test Admin']}>
      <StyledTextField
        {...props}
        InputProps={{ ...props.InputProps }}
        autoFocus={autoFocus}
        color={color}
        defaultValue={defaultValue}
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        id={id}
        label={label}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        inputRef={ref}
        required={required}
        sx={sx}
        type={type}
        value={value}
        variant={variant === 'unstyled' ? 'standard' : variant}
        onKeyDown={props.onKeyDown}
        helperText={helperText}
        onBlur={onBlur}
      />
    </AccessControl>
  );
};

export const TextInput = TextInputDefault;

export const StyledTextField = styled(
  ({
    labelSeparate,
    success,
    errorIconProps,
    successIconProps,
    successIcon = <Check {...successIconProps} />,
    errorIcon = <X color={colors.error[400]} {...errorIconProps} />,
    helperText: hint,
    labelVariant,
    labelText,
    isUnderline,
    ...styledProps
  }: Omit<TextFieldProps, 'variant'> & CommonLabelSeparateProps & TextInputProps) => {
    const helperText =
      hint && styledProps?.error ? (
        <Box lineHeight={0} p={0} m={0} display="flex" alignItems="center" gap="4px">
          {errorIcon} {hint}
        </Box>
      ) : hint && success ? (
        <Box lineHeight={0} p={0} m={0} display="flex" alignItems="center" gap="4px">
          {successIcon} {hint}
        </Box>
      ) : (
        hint
      );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <TextField {...styledProps} helperText={helperText} />;
  }
)(({
  theme,
  labelSeparate = true,
  success,
  labelVariant = 'labelSm',
  isUnderline,
  labelText = 'regular',
  ...rest // eslint-disable-next-line
}: any) => {
  const isOutlined = rest?.variant === 'outlined';
  return {
    [`& .${inputLabelClasses.error}`]: {
      color: theme.palette.text.error,
    },

    [`& .${inputLabelClasses.root}`]: {
      color: theme.palette.text.secondary,
      ...(theme.typography?.[labelVariant] ?? {}),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...(textVariant?.[labelText] ?? {}),
      ...(isUnderline ? { textDecoration: 'underline' } : {}),
    },

    [`& .${inputLabelClasses.asterisk}`]: {
      color: theme.palette.text.error,
    },

    [`& .${formHelperTextClasses.root}`]: {
      margin: '0.5px',
      ...theme.typography.labelRegularSm,

      [`&.${formHelperTextClasses.error}`]: {
        color: theme.palette.text.error,
      },
    },

    ...(isOutlined && labelSeparate
      ? {
          '&& .MuiAutocomplete-input ': {
            borderRadius: '8px',
            height: '24px',
            padding: '10px 14px',
          },
          input: { borderRadius: '8px', height: '24px', padding: '10px 14px' },

          legend: {
            maxWidth: '0px',
          },

          [`& .${inputLabelClasses.outlined}`]: {
            position: 'unset',
            transform: 'none',
            transition: 'none',
          },

          [`& .${outlinedInputClasses.root}`]: {
            borderRadius: '8px',
            marginBottom: '6px',
            marginTop: '6px',
            backgroundColor: theme.palette.bg.primary,

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderRadius: '8px',
              borderColor: theme.palette.border.primary,
              color: theme.palette.text.quaternary,
              borderWidth: '0.5px',
            },

            '&:hover': {
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: theme.palette.border.brand,
              },
            },

            [`&.${outlinedInputClasses.focused}`]: {
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: theme.palette.border.brand,
                borderWidth: '1px',
                boxShadow:
                  '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(22, 105, 99, 0.24)',
              },
            },
          },

          [`& .${inputBaseClasses.root}`]: {
            ...(success ? { backgroundColor: theme.palette.bg.successPrimary } : {}),
            [`&.${inputBaseClasses.error}`]: {
              backgroundColor: theme.palette.bg.errorPrimary,
            },
          },
        }
      : {}),

    // for success
    ...(success
      ? {
          [`& .${inputLabelClasses.root}`]: {
            color: theme.palette.text.success,
            ...(theme.typography?.[labelVariant] ?? {}),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...(textVariant?.[labelText] ?? {}),
            ...(isUnderline ? { textDecoration: 'underline' } : {}),
          },

          [`& .${formHelperTextClasses.root}`]: {
            margin: '0.5px',
            ...theme.typography.labelRegularSm,
            color: theme.palette.text.success,
          },
        }
      : {}),
  };
});
