import { createServer } from 'miragejs';
import { endpoints } from 'mocks/api';
import { DOMAIN } from 'config';

export const mockServer = (environment = 'development') => {
  const server = createServer({
    environment,

    routes() {
      const NativeXMLHttpRequest = window.XMLHttpRequest;
      // @ts-expect-error: patching a bug in pretenderjs
      window.XMLHttpRequest = function XMLHttpRequest() {
        const request = new NativeXMLHttpRequest();
        // @ts-expect-error: patching a bug in pretenderjs
        delete request.onloadend;
        return request;
      };

      this.urlPrefix = DOMAIN;

      endpoints(this);

      this.passthrough();
      this.passthrough('https://official-joke-api.appspot.com/**');
      this.passthrough('https://catfact.ninja/**');
    },
  });

  return server;
};
