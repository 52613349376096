import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import {
  ThemeProvider,
  EasyPeasyProvider,
  I18nProvider,
  ReactQueryProvider,
  NotificationProvider,
  IconProvider,
} from 'providers';
import { CssBaseline } from '@mui/material';
import { GlobalStyles } from 'styles/globalStyles';
import { TaskBar } from 'features/modalManager';
import { mockServer } from 'mocks/browser';

mockServer();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <I18nProvider>
      <ReactQueryProvider>
        <EasyPeasyProvider>
          <ThemeProvider>
            <IconProvider>
              <TaskBar />
              <CssBaseline />
              <GlobalStyles />
              <NotificationProvider />
              <RouterProvider router={router} />
            </IconProvider>
          </ThemeProvider>
        </EasyPeasyProvider>
      </ReactQueryProvider>
    </I18nProvider>
  </StrictMode>
);
