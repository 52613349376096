import { faker } from '@faker-js/faker/locale/en';
import { TaskUser } from 'features/taskboard/types/Users';
import Cookies from 'js-cookie';
import { LoginContext } from 'features/auth/types';
const loginContextJson = Cookies.get('auth_login_context');
const loginContext: LoginContext = loginContextJson ? JSON.parse(loginContextJson) : null;
export const newUser = (): TaskUser => {
  const orgType = Math.floor(Math.random() * 2) + 1; //randomally select 1 or 2
  return {
    id: faker.string.uuid(),
    name: `${faker.person.firstName()} ${faker.person.lastName()}`,
    email: faker.internet.email(),
    designerOrganizationId:
      orgType === 1 ? loginContext?.designerOrganizationId : faker.number.int(),
    organizationId:
      orgType === 1 ? loginContext?.organizationId || faker.number.int() : faker.number.int(),
    organizationTypeId: orgType,
    Organization: {
      id: loginContext?.organizationId || faker.number.int(),
      name: faker.company.name(),
      typeId: orgType,
      isStyleSupplier: faker.datatype.boolean(),
      isItemSupplier: faker.datatype.boolean(),
      isActive: faker.datatype.boolean(),
    },
  };
};

export const taskUsers: TaskUser[] = Array.from({ length: 10 }, newUser);
