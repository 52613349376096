import React from 'react';
import { type CountryCode } from 'libphonenumber-js';
import '/node_modules/flag-icons/css/flag-icons.min.css';

export interface FlagProp extends React.HTMLProps<HTMLSpanElement> {
  nationality?: CountryCode | string;
}

export const Flag = ({ nationality, ...rest }: FlagProp) => (
  <span
    {...rest}
    style={{ width: '32px', height: '22px', ...rest?.style }}
    className={`fi fi-${nationality?.toLowerCase()}`}
  />
);
