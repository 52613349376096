import { ReloadPrompt } from './components';
import { Layout } from 'components/layout';
import { Outlet, useLocation } from 'react-router-dom';
import { Iframe, iframePaths } from 'features/iframe';
import { usePageView } from 'lib/segment';
import { useUser } from 'features/auth';
import { useScriptInjector } from 'hooks/useScriptInjector';
import segmentScript from 'lib/segment/segmentInitScript';
import { AccessControl, RouterFallback } from 'features/accessControl';
import { SEGMENT_ANALYTICS_KEY } from 'config';

export const App = () => {
  const location = useLocation();
  const { data } = useUser();

  // Add Segment script to the DOM and add user
  useScriptInjector({
    id: 'segment',
    method: 'with-code',
    targetElement: 'body',
    code: segmentScript,
    loadScript: SEGMENT_ANALYTICS_KEY, // If segment key exists, load the script
    callback: () => {
      if (data?.loginContext) {
        window.analytics.identify(data.loginContext?.id, {
          name: data.loginContext?.name,
          email: data.loginContext?.email,
          organization: data.loginContext?.organizationName,
        });
      }
    },
  });

  // Register navigation to segment in an SPA
  usePageView();

  // Set app version for Segment
  window.app_version = APP_VERSION;

  // iframe
  const showIframe =
    iframePaths.includes(location.pathname) ||
    iframePaths.some(p => location.pathname.toLowerCase().includes(p));

  return (
    <Layout noPadding={showIframe}>
      <AccessControl fallback={<RouterFallback />}>
        <Outlet />
        {showIframe ? <Iframe path={location.pathname} /> : null}
        <ReloadPrompt />
      </AccessControl>
    </Layout>
  );
};
