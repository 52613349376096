import { create, CLIENT_ERROR, SERVER_ERROR, TIMEOUT_ERROR, NETWORK_ERROR } from 'apisauce';
import { DOMAIN } from 'config';
import type { ApiResponse } from 'apisauce';
import { object, string, array } from 'yup';
import { ErrorType } from '../types';
import { logoutFn } from 'features/auth';
import type { CombinedTypes } from 'features/notifications/types';

export const api = create({
  baseURL: DOMAIN,
  headers: {
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    'Access-Control-Allow-Origin': '*',
  },
  timeout: 180000,
  withCredentials: true,
});

const authMonitor = async (response: ApiResponse<unknown>) => {
  // log user out if unauthorized
  if (response.status === 401) {
    await logoutFn();
    window.location.replace('/');
  }
};
api.addMonitor(authMonitor);

export async function handleRequest<T>(
  promise: Promise<ApiResponse<T, T>>,
  notify?: (notification: CombinedTypes) => void
) {
  const res = await promise;
  if (!res.ok) {
    const errorMessage = await validateError(res as ApiResponse<ErrorType, ErrorType>);
    if (notify) {
      notify({ message: errorMessage || '', id: 'api-error', autoClose: false, isCode: true });
    }
    throw new Error(errorMessage);
  }
  const inValid = validateResponse(res);
  if (inValid || !res.data) {
    throw new Error(inValid);
  }
  return res.data;
}

function validateResponse<T>(res: ApiResponse<T, T>) {
  const contentType = res?.headers?.['content-type'];
  if (res?.config?.method === 'get') {
    if (!contentType?.includes('application/json')) {
      return `Invalid response type (${contentType}), expected application/json.`;
    }
    if (!res?.data) {
      return 'No data in response.';
    }
  }
  return '';
}

const validateError = async (res: ApiResponse<ErrorType, ErrorType>) => {
  if (res.problem === CLIENT_ERROR) {
    const errorMessage = await validateClientError(res as ApiResponse<ErrorType, ErrorType>);
    return errorMessage;
  }
  if (res.problem === SERVER_ERROR) {
    const errorMessage = await validateClientError(res as ApiResponse<ErrorType, ErrorType>);
    return errorMessage;
  }
  if (res.problem === TIMEOUT_ERROR) {
    return `Server didn't respond in time (${
      res.config?.timeout ? res.config.timeout / 1000 : 0
    } seconds).`;
  }
  if (res.problem === NETWORK_ERROR) {
    return 'Internet connection error.';
  }
  return `${res.problem} (${res.status}).`;
};

const errorSchema = object({
  code: string().required(),
  status: string().required(),
  data: object().nullable(),
  error: object({
    details: array().of(object({ type: string().required(), value: string().required() })),
  }).nullable(),
});

const validateClientError = async (res: ApiResponse<ErrorType, ErrorType>) => {
  try {
    await errorSchema.validate(res.data, { abortEarly: false });
  } catch (error) {
    const messages: string[] = [];
    if ((error as { value: { Error: { Details: { Value: string }[] } } }).value?.Error?.Details) {
      (error as { value: { Error: { Details: { Value: string }[] } } }).value.Error.Details.forEach(
        (innerError: { Value: string }) => {
          messages.push(innerError.Value);
        }
      );
    } else {
      (error as { inner: Error[] }).inner.forEach((innerError: Error) => {
        messages.push(innerError.message);
      });
    }
    return `Invalid error response (${res.status}). ${messages.join(', ')}.`;
  }
  return res?.data?.code;
};

/**
 * Converts a camelCase string to PascalCase.
 * @param str - The camelCase string.
 * @returns The PascalCase string.
 */
const camelToPascal = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Recursively converts the keys of an object from camelCase to PascalCase.
 * @param obj - The object with camelCase keys.
 * @returns The object with PascalCase keys.
 */
export const objCamelToPascal = (
  obj: Record<string, unknown> | Record<string, unknown>[]
): Record<string, unknown> | Record<string, unknown>[] => {
  if (Array.isArray(obj)) {
    return obj.map(item => objCamelToPascal(item) as Record<string, unknown>);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce(
      (acc, key) => {
        const pascalKey = camelToPascal(key);
        acc[pascalKey] = objCamelToPascal(obj[key] as Record<string, unknown>);
        return acc;
      },
      {} as Record<string, unknown>
    );
  }
  return obj;
};
