import { iconButtonClasses, IconButton as MuiIconButton, styled } from '@mui/material';
import type { IconButtonProps as MuiIconButtonProps } from '@mui/material';

interface IconButtonProps extends MuiIconButtonProps {
  destructive?: boolean;
}

export const IconButton = ({ children, ...rest }: IconButtonProps) => {
  return <StyledIconButton {...rest}>{children}</StyledIconButton>;
};
const StyledIconButton = styled(({ destructive, ...props }: IconButtonProps) => (
  <MuiIconButton {...props} />
))(({ theme, destructive }) => {
  const colors = {
    color: destructive ? theme.palette.fg.errorPrimary : theme.palette.fg.brandPrimary,
    backgroundColor: destructive ? theme.palette.bg.errorPrimary : theme.palette.bg.brandPrimary,
  };
  return {
    borderRadius: '4px',
    '&:hover': { ...colors },
    '&:focus': { ...colors },
    [`&.${iconButtonClasses.disabled}`]: {
      backgroundColor: theme.palette.bg.primaryHover,
    },
  };
});

export const smIconButton = {
  width: '20px',
  height: '20px',
  padding: '2px',
  justifyContent: 'center',
  alignItems: 'center',
};

export const mdIconButton = {
  width: '24px',
  height: '24px',
  padding: '2px',
  justifyContent: 'center',
  alignItems: 'center',
};

export const lgIconButton = {
  width: '32px',
  height: '32px',
  padding: '4px',
  justifyContent: 'center',
  alignItems: 'center',
};
