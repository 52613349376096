import styled from '@emotion/styled';
import DelogueLogoName from 'img/delogue-logo-name.svg';
import { Image } from 'components';
import { Welcome } from './Welcome';

type CenterContainerProps = {
  children: React.ReactNode;
  welcomeText: React.ReactNode;
};

export const CenterContainer = ({ children, welcomeText }: CenterContainerProps) => {
  return (
    <Container>
      <LogoContainer>
        <Image src={DelogueLogoName} alt="Delogue logo name" width="auto" />
      </LogoContainer>
      <MiddleContainer>
        <LoginContainer>{children}</LoginContainer>
        <Welcome>{welcomeText}</Welcome>
      </MiddleContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: ${p => p.theme.palette.bg.secondary};
  > div {
    flex-shrink: 0;
  }
`;

const LogoContainer = styled.div`
  margin-top: 36px;
`;

const MiddleContainer = styled.div`
  width: 852px;
  background-color: ${p => p.theme.palette.fg.white};
  display: flex;
  flex-direction: row;
  border-radius: 16px;
  border: ${p => `0.5px solid ${p.theme.palette.border.primarySubtle}`};
  box-shadow: 4px 8px 16px 0px rgba(34, 34, 34, 0.16);
  overflow: hidden;
`;

const LoginContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div``;
