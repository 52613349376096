export const StyleRoutes = {
  path: 'style',
  lazy: () => import('../pages/style'),
  children: [
    { path: 'workflow', lazy: () => import('../pages/style/workflow') },
    { path: ':id/communicationlog', lazy: () => import('../pages/style/communication') },
    { path: 'sample-request', lazy: () => import('../pages/style/sample-request') },
    { path: 'style-files', lazy: () => import('../pages/style/style-files') },
    { path: 'item-list', lazy: () => import('../pages/style/item-list') },
    { path: 'measurement-chart', lazy: () => import('../pages/style/measurement-chart') },
    { path: 'custom-fields', lazy: () => import('../pages/style/custom-fields') },
    { path: 'prices', lazy: () => import('../pages/style/prices') },
    { path: 'description', lazy: () => import('../pages/style/description') },
    { path: 'skus', lazy: () => import('../pages/style/skus') },
    { path: 'care-instructions', lazy: () => import('../pages/style/care-instructions') },
    { path: 'compliance', lazy: () => import('../pages/style/compliance') },
  ],
};
