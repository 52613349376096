import { codeList } from '../utils/codeList';

export type Children = keyof typeof codeList;

type CodesProps = {
  text: string;
};

export const Codes = ({ text }: CodesProps) => {
  const realText = codeList[text as keyof typeof codeList];

  if (!realText) {
    return text;
  }

  return realText;
};
