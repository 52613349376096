import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import type { Icon } from '@phosphor-icons/react';
import { colors } from 'styles/theme/colors';
import { Text as TextComponents } from 'components/text';

type MenuItemProps = {
  url: string;
  MenuIcon: Icon;
  children: React.ReactNode;
  topPadding?: boolean;
  selected?: boolean;
  onClick: () => void;
  extend: boolean;
};

export const MenuItem = ({
  url,
  MenuIcon,
  topPadding = false,
  selected = false,
  children,
  onClick,
  extend,
}: MenuItemProps) => {
  return (
    <Link to={url} onClick={onClick}>
      <Item topPadding={topPadding} selected={selected} extend={extend}>
        <IconCon>
          <MenuIcon
            weight={selected ? 'fill' : undefined}
            color={selected ? colors.brand[400] : colors.grey[700]}
            strokeWidth="0.9px"
          />
        </IconCon>
        <Text color={selected ? colors.grey[900] : colors.grey[600]} extend={extend}>
          {children}
        </Text>
      </Item>
    </Link>
  );
};

const Item = styled.div<{ topPadding: boolean; selected: boolean; extend: boolean }>`
  position: relative;
  width: ${p => (p.extend ? 200 : 40)}px;
  transition: width 0.2s ease-in-out;
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row;
  height: 40px;
  ${p => (p.topPadding ? 'margin-top: 40px;' : '')}
  ${p => (p.selected ? `background-color: ${p.theme.palette.bg.brandSubtle};` : '')}
  border-radius: 8px;
  :hover {
    background-color: ${p => p.theme.palette.bg.primaryHover};
  }
`;

const IconCon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  z-index: 1;
`;

const Text = styled(TextComponents)<{ extend: boolean }>`
  position: absolute;
  left: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  opacity: ${p => (p.extend ? 1 : 0)};
  max-width: ${p => (p.extend ? 'calc(100% - 48px)' : '0')};
  transition:
    opacity 0.2s ease-in-out,
    max-width 0.2s ease-in-out;
`;
