import { useUser } from 'features/auth/hooks';
import styled from '@emotion/styled';
import { LoginContext } from 'features/auth/types';
import { Button, BaseButton, Text } from 'components';
import { CenterContainer } from './CenterContainer';
import { Trans } from '@lingui/macro';
import { SignIn } from '@phosphor-icons/react';
import { CircularProgress } from '@mui/material';

type SwitchCompanyProps = {
  onSelectCompany: (loginContext: LoginContext) => void;
  onLogout: () => void;
  isLoading: boolean;
};

export const SwitchCompanyForm = ({
  onSelectCompany,
  onLogout,
  isLoading = false,
}: SwitchCompanyProps) => {
  const { data } = useUser({});

  return (
    <CenterContainer
      welcomeText={
        <Text variant="bodyMd" fontFamily="Gazpacho">
          <Trans>... Welcome back</Trans>
        </Text>
      }
    >
      <List>
        <div>
          <Header>
            <Trans>Select Company</Trans>
          </Header>
          {isLoading ? <CircularProgress /> : null}
          {!isLoading &&
            data?.user?.loginContexts?.map((loginContext: LoginContext) => (
              <BaseButton key={loginContext?.id} onClick={() => onSelectCompany(loginContext)}>
                <SignIn /> {loginContext?.organizationName}
                {loginContext?.organizationType === 2 ? ' (Supplier)' : ''}
              </BaseButton>
            ))}
        </div>
        <Logout>
          <Button color="primary" variant="contained" onClick={() => onLogout()}>
            Log out
          </Button>
        </Logout>
      </List>
    </CenterContainer>
  );
};

const Logout = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const List = styled.div`
  margin: 16px 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  button {
    padding: 8px 12px;
    border-radius: 4px;
    margin-bottom: 8px;
  }
`;

const Header = styled.h1`
  margin-bottom: 16px;
  color: ${p => p.theme.palette.text.primary};
  font-weight: 600;
  font-size: 18px;
`;
