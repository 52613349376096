import styled from '@emotion/styled';
import {
  inputLabelClasses,
  InputLabel as MuiInputLabel,
  type InputLabelProps as MuiInputLabelProps,
} from '@mui/material';
import { CommonLabelSeparateProps } from './types';
import { textVariant } from 'components/text';

interface InputLabelProps
  extends MuiInputLabelProps,
    Pick<CommonLabelSeparateProps, 'labelVariant' | 'isUnderline' | 'success' | 'labelText'> {}

export const InputLabel = ({ ...props }: InputLabelProps) => {
  return <StyledInputLabel {...props} />;
};

const StyledInputLabel = styled(
  ({ labelVariant, isUnderline, success, labelText, ...props }: InputLabelProps) => (
    <MuiInputLabel {...props} />
  )
)(({
  labelVariant = 'labelSm',
  isUnderline = false,
  success = false,
  labelText = 'semiBold',
  theme,
  ...rest
}) => {
  return {
    color: theme.palette.text.secondary,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...(theme.typography?.[labelVariant] ?? {}),
    ...(textVariant?.[labelText] ?? {}),
    ...(isUnderline ? { textDecoration: 'underline' } : {}),

    [`.${inputLabelClasses.error}`]: {
      color: theme.palette.text.error,
    },

    [`& .${inputLabelClasses.asterisk}`]: {
      color: theme.palette.text.error,
    },
    ...(success
      ? {
          color: theme.palette.text.success,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...(theme.typography?.[labelVariant] ?? {}),
          ...(textVariant?.[labelText] ?? {}),
          ...(isUnderline ? { textDecoration: 'underline' } : {}),
        }
      : {}),
  };
});
