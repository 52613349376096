import { Box, Typography, useTheme, Stack } from '@mui/material';
import { WarningCircle } from '@phosphor-icons/react';
import { FileTypePreview } from './FileTypePreview';
import { LinearProgressWithLabel } from 'components/progressBar';
import type { PreviewAreaProps } from '../types';
import styled from '@emotion/styled';

export const PreviewArea = ({ filesUploadData, layoutOptions, sx }: PreviewAreaProps) => {
  const theme = useTheme();

  return (
    <Box sx={sx}>
      <Stack
        component="ul"
        spacing={{ xs: 1, sm: 4 }}
        direction="column"
        useFlexGap
        flexWrap="wrap"
        {...layoutOptions}
        sx={{ ...sx, padding: theme.spacing(2), gap: theme.spacing(2) }}
      >
        {filesUploadData.data.map(({ id, file, progress }) => {
          return (
            <ListItem key={id}>
              <FileTypePreview file={file} size={24} />
              <Typography>{file.path}</Typography>
              {filesUploadData.status === 'error' ? (
                <WarningCircle
                  data-testid="icon-warning"
                  size={24}
                  color={theme.palette.error.main}
                />
              ) : (
                <Box sx={{ width: '200px' }}>
                  <LinearProgressWithLabel
                    sx={{ height: '24px', borderRadius: '4px' }}
                    value={progress || 0}
                  />
                </Box>
              )}
            </ListItem>
          );
        })}
      </Stack>
    </Box>
  );
};

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
`;
