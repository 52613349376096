import type { PaginateProps, PaginateReturn } from 'types';

export const paginate = <T>({
  items = [],
  perPage = 20,
  page = 1,
}: PaginateProps<T>): PaginateReturn<T> => {
  const matrix: T[][] = items.reduce<T[][]>((acc: T[][], item: T, index: number) => {
    const pageIndex = Math.floor(index / perPage);

    if (!acc[pageIndex]) {
      acc[pageIndex] = [];
    }

    acc[pageIndex].push(item);

    return acc;
  }, []);

  const validPage = page - 1 < matrix.length && page > 0 ? page - 1 : 0;
  const pageItems = matrix[validPage] || [];

  return {
    pageItems,
    pageSize: pageItems.length,
    numberOfPages: matrix.length,
  };
};

export const range = (len: number) => {
  const arr: number[] = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

export function makeData<T>(len: number, fakeObject: (identifyer: number) => T) {
  return range(len).map((_, index): T => {
    return {
      ...fakeObject(index),
    };
  });
}

export function makeChildData<T>(
  currentParentId: number,
  len: number,
  fakeObject: (parentId: number, identifyer: number) => T
) {
  return range(len).map((_, index): T => {
    return {
      ...fakeObject(currentParentId, index),
    };
  });
}
