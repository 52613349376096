import { DOMAIN } from 'config';
import type { Server } from 'miragejs';

export const accessControlEndpoints = (server: Server) => {
  server.get(`${DOMAIN}/access-control`, () => {
    return { method: 'get' };
  });
  server.post(`${DOMAIN}/access-control`, (schema, request) => {
    const requestData = JSON.parse(request.requestBody);
    return { method: 'post', requestData };
  });
  server.put(`${DOMAIN}/access-control`, (schema, request) => {
    const requestData = JSON.parse(request.requestBody);
    return { method: 'put', requestData };
  });
  server.delete(`${DOMAIN}/access-control`, () => {
    return { method: 'delete' };
  });
};
