import {
  Typography as MuiTypography,
  styled,
  type TypographyProps as MuiTypographyProps,
} from '@mui/material';

interface TextProps extends MuiTypographyProps {
  underlined?: boolean;
}

export const Text = (props: TextProps) => {
  return <StyledTypography {...props} />;
};

const StyledTypography = styled(({ underlined, ...props }: TextProps) => (
  <MuiTypography {...props} />
))(({ theme, underlined }) => {
  return underlined ? { textDecoration: 'underline' } : {};
});

export const fontFamily = [
  'Open Sans',
  'system-ui',
  '-apple-system',
  'Segoe UI',
  'Roboto',
  'Ubuntu',
  'Cantarell',
  'Noto Sans',
].join(',');

const commonBodyProps = {
  fontFamily,
  fontStyle: 'normal',
};
export const textVariant = {
  regular: {
    fontWeight: 400,
  },
  medium: {
    fontWeight: 500,
  },
  semiBold: {
    fontWeight: 600,
  },
  bold: {
    fontWeight: 700,
  },
};

const bodySize = {
  xl: { fontSize: '20px' },
  lg: { fontSize: '18px' },
  md: { fontSize: '16px' },
  sm: { fontSize: '14px' },
  xs: { fontSize: '12px' },
};

const commonLabelProps = {
  fontFamily,
  fontStyle: 'normal',
};

const labelSize = {
  md: { fontSize: '16px' },
  sm: { fontSize: '14px' },
};

const commonHeadlineProps = {
  fontFamily,
  fontStyle: 'normal',
};

const headlineSize = {
  xl: { fontSize: '36px' },
  lg: { fontSize: '30px' },
  md: { fontSize: '24px' },
  sm: { fontSize: '20px' },
  xs: { fontSize: '18px' },
};

const commonDisplayProps = {
  fontFamily: 'Gazpacho',
  fontStyle: 'normal',
};

const displaySize = {
  xl: { fontSize: '72px' },
  lg: { fontSize: '60px' },
  md: { fontSize: '48px' },
  sm: { fontSize: '30px' },
};

export const typographyStyles = {
  displayXl: {
    ...displaySize.xl,
    ...commonDisplayProps,
  },
  displayLg: {
    ...displaySize.lg,
    ...commonDisplayProps,
  },
  displayMd: {
    ...displaySize.md,
    ...commonDisplayProps,
  },
  displaySm: {
    ...displaySize.sm,
    ...commonDisplayProps,
  },

  headlineXl: {
    ...headlineSize.xl,
    ...commonHeadlineProps,
  },
  headlineLg: {
    ...headlineSize.lg,
    ...commonHeadlineProps,
  },
  headlineMd: {
    ...headlineSize.md,
    ...commonHeadlineProps,
  },
  headlineSm: {
    ...headlineSize.sm,
    ...commonHeadlineProps,
  },
  headlineXs: {
    ...headlineSize.xs,
    ...commonHeadlineProps,
  },

  bodyXl: {
    ...bodySize.xl,
    ...commonBodyProps,
  },
  bodyLg: {
    ...bodySize.lg,
    ...commonBodyProps,
  },
  bodyMd: {
    ...bodySize.md,
    ...commonBodyProps,
  },
  bodySm: {
    ...bodySize.sm,
    ...commonBodyProps,
  },
  bodyXs: {
    ...bodySize.xs,
    ...commonBodyProps,
  },

  labelMd: {
    ...labelSize.md,
    ...commonLabelProps,
  },
  labelSm: {
    ...labelSize.sm,
    ...commonLabelProps,
  },
};
