import { faker } from '@faker-js/faker/locale/en';
import type { LinkedTo } from 'types/table';
import { workflowTaskEntityType } from './task';
import { FieldData } from 'features/taskboard/types/Tasks';

export const linkedData = (): LinkedTo => {
  const taskEntityType = faker.helpers.arrayElement([
    workflowTaskEntityType[1],
    workflowTaskEntityType[2],
    workflowTaskEntityType[3],
    // taskEntityTypes.Sampling,
  ]);
  return {
    id: faker.number.int(7),
    userDefinedId: faker.lorem.word(7),
    name: faker.commerce.productName(),
    entityType: taskEntityType,
    image:
      taskEntityType === workflowTaskEntityType[1] || taskEntityType === workflowTaskEntityType[2]
        ? faker.image.urlPicsumPhotos()
        : '',
    season: { id: faker.number.int(), name: faker.lorem.word(5) } as FieldData,
    brand: { id: faker.number.int(), name: faker.lorem.word(5) } as FieldData,
    supplier: { id: faker.number.int(), name: faker.lorem.word(5) } as FieldData,
    certificateType: faker.lorem.word(5),
  };
};

export const linkedTo: LinkedTo[] = Array.from({ length: 110 }, linkedData);
