import { faker } from '@faker-js/faker/locale/en';
import { AdminWorkflow, AdminWorkflowTask } from 'features/taskboard/types/Tasks';
//import { type TaskUser } from 'features/taskboard/types/Users';
import { newUser } from './taskUser';

const generateAdminWorkflow = (): AdminWorkflow => {
  return {
    id: faker.number.int(),
    name: faker.company.name(),
    organizationId: faker.number.int(),
    position: faker.number.int(),
    colorCode: faker.internet.color(),
  };
};

// Helper function to generate a random AdminWorkflowTask against a given AdminWorkflow
const generateAdminWorkflowTask = (workflow: AdminWorkflow): AdminWorkflowTask => ({
  id: faker.number.int(),
  name: faker.company.catchPhrase(),
  AssignedToPerson: newUser(),
  DaysBeforeDeadline: faker.number.int({ min: 1, max: 30 }),
  AssignedTo: faker.string.alpha(),
  AdminWorkflow: workflow,
});

// Generate an array of tasks for each workflow
const generateTasksForWorkflow = (
  workflows: AdminWorkflow[],
  totalTasks: number
): AdminWorkflowTask[] => {
  const tasks: AdminWorkflowTask[] = [];
  let workflowIndex = 0; // Start with the first workflow

  for (let i = 0; i < totalTasks; i++) {
    const currentWorkflow = workflows[workflowIndex];
    tasks.push(generateAdminWorkflowTask(currentWorkflow));

    // Switch to the next workflow after every 10 tasks
    if ((i + 1) % 10 === 0) {
      workflowIndex = (workflowIndex + 1) % workflows.length;
    }
  }

  return tasks;
};

const workflows: AdminWorkflow[] = [];
// Generate the specified number of workflows
for (let i = 0; i < 15; i++) {
  workflows.push(generateAdminWorkflow());
}
export const adminWorkflowTasks = generateTasksForWorkflow(workflows, 100);
