import { useEffect } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { useNotification } from 'features/notifications';
import { t } from '@lingui/macro';

export const ReloadPrompt = () => {
  const { notify } = useNotification();
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(_, r) {
      if (r) {
        setInterval(() => {
          r.update();
        }, 1000 * 60);
      }
    },
  });

  useEffect(() => {
    if (needRefresh) {
      notify.info({
        message: t`A new version of Delogue PLM is available. Click OK to refresh your browser and load the new version.`,
        id: 'new-version-ready',
        header: t`New version ready`,
        autoClose: false,
        onClose: btn => {
          if (btn !== 'ok') {
            close();
          }
          if (btn === 'ok') {
            updateServiceWorker();
          }
        },
        actions: [
          { id: 'ok', label: t`OK` },
          { id: 'cancel', label: t`Cancel` },
        ],
      });
    }
  }, [needRefresh]);

  const close = () => {
    setNeedRefresh(false);
  };

  return null;
};
