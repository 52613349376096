export const user = {
  isMultiselect: true,
  loginContexts: [
    {
      id: 'f3b36f89-1ef3-4fc4-8fcd-1d215d629339',
      name: 'John Smith',
      organizationType: 1,
      organizationId: 2,
      organizationName: 'Best Clothing',
      brands: [
        {
          id: 31596,
          name: '222124567sac',
          organizationId: 2,
          status: false,
          address: null,
          zipCode: null,
          city: null,
          state: null,
          country: null,
          logo: {
            id: '4d0aa1c4-189a-40f6-9046-71ed8cca6e5e',
            name: '3.jpg',
            url: 'https://s3-eu-west-1.amazonaws.com/designhubtest/organization_2/temp/5474935a-6592-403a-867f-5c40d4869733/3.jpg',
            metadata: '{"ContentType":"image/jpeg"}',
            size: 606207,
            fileWidth: 0,
            fileHeight: 0,
            provider: 'DesignHub.Business.FileResource.S3FileResource',
            createdBy: null,
            createdOn: '2021-12-10T09:38:39.293',
            storageLocation: 'organization_2/temp/5474935a-6592-403a-867f-5c40d4869733',
            relatedEntityType: null,
            relatedEntityId: null,
            isLink: false,
            isDeletedFromS3: false,
            resourceThumbnailAssociation: null,
            thumbnails: null,
            fileIconUrl: null,
            thumbnailNotFound: false,
          },
          isDeleted: false,
          deletedOn: null,
          marginFactor: null,
          userDefinedId: '12',
          logoThumbnails: [],
        },
      ],
      email: 'test@headfitted.com',
      roleNames: [
        'Company Admin',
        'Company User',
        'Price',
        'SKU-Barcodes',
        'Color Admin',
        'Supplier Manager',
        'Care Instructions',
        'Analytics',
        'Import/Export',
        'Integrator',
        'Compliance Admin',
        'Compliance User',
      ],
      designerOrganizationName: 'Best Clothing',
      designerOrganizationId: 2,
    },
  ],
};

export const supplierUser = {
  isMultiselect: true,
  loginContexts: [
    {
      id: 'a84fb267-7d08-44f4-8ad8-a6b122990edf',
      name: 'John Smith',
      organizationType: 2,
      organizationName: 'Best Clothing Supplier',
      brands: [
        {
          id: 31596,
          name: '222124567sac',
          organizationId: 2,
          status: false,
          address: null,
          zipCode: null,
          city: null,
          state: null,
          country: null,
          logo: {
            id: '4d0aa1c4-189a-40f6-9046-71ed8cca6e5e',
            name: '3.jpg',
            url: 'https://s3-eu-west-1.amazonaws.com/designhubtest/organization_2/temp/5474935a-6592-403a-867f-5c40d4869733/3.jpg',
            metadata: '{"ContentType":"image/jpeg"}',
            size: 606207,
            fileWidth: 0,
            fileHeight: 0,
            provider: 'DesignHub.Business.FileResource.S3FileResource',
            createdBy: null,
            createdOn: '2021-12-10T09:38:39.293',
            storageLocation: 'organization_2/temp/5474935a-6592-403a-867f-5c40d4869733',
            relatedEntityType: null,
            relatedEntityId: null,
            isLink: false,
            isDeletedFromS3: false,
            resourceThumbnailAssociation: null,
            thumbnails: null,
            fileIconUrl: null,
            thumbnailNotFound: false,
          },
          isDeleted: false,
          deletedOn: null,
          marginFactor: null,
          userDefinedId: '12',
          logoThumbnails: [],
        },
      ],
      email: 'test@headfitted.com',
      roleNames: ['Supplier Admin', 'Price', 'Order'],
      designerOrganizationName: 'Best Clothing',
      designerOrganizationId: 2,
      organizationId: 63,
    },
  ],
};

export const loggedInUser = {
  OrganizationId: 2,
  OrganizationTypeId: 1,
  Id: 'f3b36f89-1ef3-4fc4-8fcd-1d215d629339',
  ActiveUsersLimit: 55,
  LoginId: 'f3b36f89-1ef3-4fc4-8fcd-1d215d629339',
  Name: 'John Smith',
  FirstName: 'John',
  LastName: 'Smith',
  Email: 'test@headfitted.com',
  Password: null,
  Status: 0,
  Organization: {
    Users: [],
    Id: 2,
    Name: 'Best Clothing',
    TypeId: 1,
    Address: null,
    ZipCode: null,
    City: 'pune',
    StateOrRegion: null,
    WebSite: 'www.bestclothing.com',
    PhoneNumber: null,
    FaxNumber: null,
    VATNumber: null,
    LocalCurrency: {
      Id: 27,
      CountryCurrency: 'Denmark Krone',
      CurrencyCode: 'DKK',
      Value: 0,
      IsActive: false,
      Name: null,
    },
    Country: { Id: 1, Name: 'Denmark', Code: 'DA' },
    IsActive: true,
    IsUniqueStyleNumber: true,
    IsAutoStyleNumberOn: false,
    IsStyleNumberGeneratorActive: false,
    UserDefinedSupplierId: null,
    Modules: [
      { Id: 38, Key: 37, Name: '2 factor' },
      { Id: 44, Key: 43, Name: 'Advanced custom fields' },
      { Id: 49, Key: 48, Name: 'Advanced Custom Report' },
      { Id: 21, Key: 20, Name: 'Advanced default values' },
      { Id: 24, Key: 23, Name: 'Barcodes' },
      { Id: 42, Key: 39, Name: 'BI data' },
      { Id: 7, Key: 7, Name: 'Bisgaard print item list / comment' },
      { Id: 50, Key: 49, Name: 'Bulk Update From Excel' },
      { Id: 43, Key: 42, Name: 'Care Instructions' },
      { Id: 51, Key: 50, Name: 'Care Instructions Template' },
      { Id: 33, Key: 32, Name: 'Colorway' },
      { Id: 11, Key: 10, Name: 'Company Custom Export' },
      { Id: 57, Key: 56, Name: 'Compliance' },
      { Id: 16, Key: 16, Name: 'Custom Fields per color' },
      { Id: 41, Key: 41, Name: 'Custom fields per size' },
      { Id: 45, Key: 45, Name: 'Custom Terms' },
      { Id: 40, Key: 40, Name: 'Dashboard SR Bulk Update' },
      { Id: 4, Key: 4, Name: 'DAY' },
      { Id: 19, Key: 17, Name: 'Description Table' },
      { Id: 9, Key: 8, Name: 'ECommerce' },
      { Id: 48, Key: 47, Name: 'Extensions' },
      { Id: 17, Key: 14, Name: 'File server upload' },
      { Id: 46, Key: 44, Name: 'Generator' },
      { Id: 34, Key: 33, Name: 'Hierarchy Allowed Values' },
      { Id: 53, Key: 52, Name: 'Illustrator Plugin' },
      { Id: 39, Key: 38, Name: 'Import' },
      { Id: 47, Key: 46, Name: 'Item and Style Import' },
      { Id: 6, Key: 6, Name: 'Item supplied by' },
      { Id: 5, Key: 5, Name: 'ItemOverviewReport' },
      { Id: 32, Key: 31, Name: 'LinkMeasurements' },
      { Id: 18, Key: 18, Name: 'Lock Season' },
      { Id: 20, Key: 19, Name: 'Marketing' },
      { Id: 52, Key: 51, Name: 'Mobile App' },
      { Id: 15, Key: 15, Name: 'Multiple Languages' },
      { Id: 3, Key: 3, Name: 'MyReport' },
      { Id: 10, Key: 9, Name: 'Orders' },
      { Id: 28, Key: 27, Name: 'Out of office' },
      { Id: 2, Key: 2, Name: 'Prices' },
      { Id: 26, Key: 25, Name: 'Print Settings' },
      { Id: 54, Key: 53, Name: 'Pro' },
      { Id: 13, Key: 12, Name: 'Pushed Export' },
      { Id: 27, Key: 26, Name: 'Quick Search' },
      { Id: 36, Key: 35, Name: 'Relation of Styles' },
      { Id: 23, Key: 22, Name: 'Sample Request Location' },
      { Id: 14, Key: 13, Name: 'Scheduled Export' },
      { Id: 31, Key: 30, Name: 'SeasonAccess' },
      { Id: 55, Key: 54, Name: 'Shopify' },
      { Id: 22, Key: 21, Name: 'Simulate price' },
      { Id: 56, Key: 55, Name: 'SpySystem' },
      { Id: 1, Key: 1, Name: 'Standard' },
      { Id: 35, Key: 34, Name: 'Supplier sync' },
      { Id: 29, Key: 28, Name: 'Thumbnail Print Settings' },
      { Id: 12, Key: 11, Name: 'UTG color module' },
      { Id: 37, Key: 36, Name: 'Webhook' },
      { Id: 25, Key: 24, Name: 'WIP' },
      { Id: 23, Key: 22, Name: 'Sample Request Location' },
    ],
    CreatedDate: '/Date(1335830400000)/',
    ExpiryDate: '/Date(1746316800000)/',
    CompanyType: { Id: 1, Name: 'Clothes' },
    Brands: [],
    StyleServiceDetails: null,
    FTPStyleExportTime: null,
    ItemServiceDetails: null,
    FTPItemExportTime: null,
    SupplierServiceDetails: null,
    IsStyleSupplier: false,
    IsItemSupplier: false,
    PriceSettings: null,
    RootFolderToSync: '17-09-2023',
    BrandNames: [],
  },
  DesignerOrganization: null || undefined,
  AccessToAllBrands: false,
  IsTaskOrContactsAssign: false,
  Brands: [],
  AdminUnits: null,
  AdminFacilityType: null,
  Roles: [
    { Id: 1, Name: 'Company Admin' },
    { Id: 4, Name: 'Company User' },
    { Id: 7, Name: 'Price' },
    { Id: 11, Name: 'SKU-Barcodes' },
    { Id: 12, Name: 'Color Admin' },
    { Id: 14, Name: 'Care Instructions' },
    { Id: 15, Name: 'Analytics' },
    { Id: 16, Name: 'Import/Export' },
    { Id: 17, Name: 'Integrator' },
    { Id: 18, Name: 'Compliance Admin' },
    { Id: 19, Name: 'Compliance User' },
  ],
  RoleNames: [
    'Company Admin',
    'Price',
    'SKU-Barcodes',
    'Care Instructions',
    'Analytics',
    'Import/Export',
    'Integrator',
    'Compliance Admin',
    'Compliance User',
  ],
  FeatureFlags: [
    { Name: 'flag-disabled', IsDisabled: 1 },
    { Name: 'flag-enabled', IsDisabled: 0 },
  ],
  ListenStyleMessages: true,
  ListenStyleSampleRequest: true,
  ListenItemMessages: false,
  ListenItemSampleRequest: false,
  DisplayStyleProperty: 2,
  DisplayItemProperty: 2,
  OutOfOfficeFromDate: null,
  OutOfOfficeToDate: null,
  OutOfOfficeMessage: null,
  Environment: 'PRODUCTION',
  PlanTier: 'Professional',
  PlanType: 'Customer',
  IsSeasonAccessible: false,
  SignUpDate: '/Date(1625035764463)/',
  AcceptTermRequired: false,
  TermFileResource: {
    Id: 'c5af2c62-1398-4d6c-a129-c64970f3b806',
    Name: 'dummy.pdf',
    URL: 'https://mydprdst1.blob.core.windows.net/assets/organization_2/organizationcustomterms_2/04184fac-2ab3-4a5e-994e-8bc36ce06d51/dummy.pdf',
    Metadata: '{"ContentType":"application/pdf"}',
    Size: 13264,
    FileWidth: 0,
    FileHeight: 0,
    Provider: 'DesignHub.Business.FileResource.AzureBlobFileResource',
    CreatedBy: null,
    CreatedOn: '/Date(1706074577930)/',
    StorageLocation:
      'organization_2/organizationcustomterms_2/04184fac-2ab3-4a5e-994e-8bc36ce06d51',
    RelatedEntityType: 17,
    RelatedEntityId: 2,
    IsLink: false,
    IsDeletedFromS3: false,
    ResourceThumbnailAssociation: null,
    Thumbnails: null,
    FileIconUrl: null,
    ThumbnailNotFound: false,
  },
  CustomTermName: 'ct',
  IsLoginHasCustomTermModuleAccess: true,
  NotifyForComplianceMessages: false,
  NotifyForComplianceQualityTopicMessages: false,
  IsUserOutOfOffice: false,
  OutOfOfficeNotifyMessage:
    'Sune is out of office from \u003cstrong\u003e01-Jan-001\u003c/strong\u003e to \u003cstrong\u003e01-Jan-001\u003c/strong\u003e.\u003cbr/\u003e ',
  UserTransfer: null,
  IsPauseNotification: false,
  IsTwoFactorEnabled: false,
  IsQRCodeGenerated: false,
  CriticalPlatformUpdatesSubscription: true,
  PlatformNewsletterSubscription: true,
  SystemAndConfigurationUpdatesSubscription: true,
  ShowStyleMainTab: true,
  ShowStyleRecentTab: true,
  ShowItemMainTab: true,
  ShowItemRecentTab: true,
  SignalrHubUrl: 'https://myd-prd-signalr-func.azurewebsites.net/',
  SignalrHubToken: '5xriNG819RJwOAzFua80XTA==',
};

export const roleNames = {
  'Company Admin': 'Company Admin',
  'Supplier Admin': 'Supplier Admin',
  Profiler: 'Profiler',
  'Company User': 'Company User',
  'Supplier User': 'Supplier User',
  Sysadmin: 'Sysadmin',
  Price: 'Price',
  Superadmin: 'Superadmin',
  Order: 'Order',
  Marketing: 'Marketing',
  'SKU-Barcodes': 'SKU-Barcodes',
  'Color Admin': 'Color Admin',
  'Supplier Manager': 'Supplier Manager',
  'Care Instructions': 'Care Instructions',
  Analytics: 'Analytics',
  'Import/Export': 'Import/Export',
  Integrator: 'Integrator',
  'Compliance Admin': 'Compliance Admin',
  'Compliance User': 'Compliance User',
  'Batch Modifications': 'Batch Modifications',
};
