import { Response } from 'miragejs';
import type { Server } from 'miragejs';

export const imageEndpoints = (server: Server) => {
  server.get('http://localhost:4200/images/:name', async (schema, request) => {
    const buffer = await fetch(`/src/mocks/data/images/${request.params.name}.jpeg`).then(
      response => response.arrayBuffer()
    );
    return new Response(
      200,
      {
        'Content-Type': 'image/jpeg',
      },
      buffer
    );
  });
};
