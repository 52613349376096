import { Chip, type ChipProps } from './Chip';
import { styled } from '@mui/material';
import type { ComboBoxApi } from 'components/inputs/types';
import { useGet } from 'lib/api';
import type { Response } from 'types';

export type ChipsListProps = {
  chips: ChipProps[];
  onDelete?: (chip: ChipProps) => void;
  api?: ComboBoxApi;
};

export const ChipsList = ({ chips, onDelete, api }: ChipsListProps) => {
  // const [chipsData, setChipsData] = useState<readonly ChipProps[]>(chips || []);

  const { data } = useGet<Response<ChipProps[]>>(api?.url || '', api?.key || '', true, {
    enabled: !!api,
  });

  const transform = (apiData: Response<ChipProps[]>) => {
    if (api) {
      const transformed = api.responseTransform(apiData);
      return chips.map(chip => ({
        ...chip,
        label: transformed.find(d => d.id.toString() === chip.id?.toString())?.label || chip.label,
      }));
    }
    return [];
  };

  const chipsData = data && api ? transform(data) : chips;

  const handleDelete = (chipToDelete: ChipProps) => () => {
    onDelete && onDelete(chipToDelete);
  };

  return (
    <List>
      {chipsData.map(chip => {
        return <Chip component="li" key={chip.id} {...chip} onDelete={handleDelete(chip)} />;
      })}
    </List>
  );
};

const List = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(3),
}));
